import React from "react";
import Main from "components/layout/Main";
import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import RawHTMLStyled from "components/RawHTMLStyled";
import ContentGrid from "components/ContentGrid";
import PointerParent from "components/PointerParent";
import JumbotronCard from "components/JumbotronCard";
import backgroundOverlay from "assets/images/header_skew/covid_header.jpg";

import ScrollToTop from "components/ScrollToTop";

export default function covidPage() {
  const title = "COVID-19 Resource Guide";
  const points = ["p1", "p2"];
  return (
    <div>
      <Main page={""} pagetitle={title}>
        <HeaderSkew
          title={title}
          subtitle=""
          url="faq"
          background={backgroundOverlay}
          backgroundColor="404"
        >
          <JumbotronCard
            type="white-spaced-small"
            style={{ maxWidth: "600px" }}
          >
            <>
              <p>
                During this unprecedented time, nothing is more important to us
                than the health, safety, and continued success of our employees,
                customers, partners and communities.
              </p>
            </>
          </JumbotronCard>
        </HeaderSkew>
        <PointerParent points={points}>
          <Block
            layer="1"
            name="About Us"
            guides={2}
            appearance="grid-top--covid"
          >
            <ContentGrid appearance="two-columns">
              <div id="p1">
                <div data-aos="fade-up">
                  <Heading type="h2" appearance="h1--dark">
                    We’re Here for You
                  </Heading>
                  <RawHTMLStyled appearance="slim">
                    <p appearance="dark">
                      To protect our employees, we’ve seamlessly transitioned to
                      a virtual workspace, and are operating from within the
                      safety of our homes. But despite this transition, you can
                      expect the same great service on which we pride ourselves.
                    </p>

                    <p appearance="dark">
                      <br />
                      <strong style={{ color: "#555" }}>
                        Solar Loan Customers – Payments & Loan Servicing
                      </strong>
                      <br />
                      <a
                        href="tel:800-345-9372"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#555", textDecoration: "underline" }}
                      >
                        800-345-9372
                      </a>
                      <br />
                      <a
                        href="mailto:Customerservice@loanpalsupport.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#555", textDecoration: "underline" }}
                      >
                        Customerservice@loanpalsupport.com
                      </a>
                    </p>
                    <p>
                      <strong>
                        Solar Loan Customers/Intallers – Loans in process
                      </strong>
                      <br />
                      <a
                        href="tel:844-910-0111"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#555", textDecoration: "underline" }}
                      >
                        844-910-0111
                      </a>
                      <br />
                      <a
                        href="mailto:loanpalsolarops@loanpal.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#555", textDecoration: "underline" }}
                      >
                        loanpalsolarops@loanpal.com
                      </a>
                      <br />
                    </p>
                    <p>
                      <strong>Solar Partner Inquiries</strong>
                      <br />
                      <a
                        href="mailto:solarbd@loanpal.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#555", textDecoration: "underline" }}
                      >
                        solarbd@loanpal.com
                      </a>
                    </p>

                    <p>
                      <strong>Mortgage Customers</strong>
                      <br />
                      <a
                        href="tel:844-562-6725"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#555", textDecoration: "underline" }}
                      >
                        844-562-6725
                      </a>
                      <br />
                      <a
                        href="mailto:customercare@loanpal.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#555", textDecoration: "underline" }}
                      >
                        customercare@loanpal.com
                      </a>
                    </p>
                  </RawHTMLStyled>
                </div>
                <div className="spacer-mobile" />
              </div>

              <div id="p2">
                <div data-aos="fade-up">
                  <Heading type="h2" appearance="h1--dark">
                    Our Vendor Guidelines
                  </Heading>

                  <RawHTMLStyled appearance="slim">
                    <p>
                      While Loanpal is working virtually, cases arise where one
                      of our vendors need to visit your home to keep your
                      transaction moving forward. Rest assured, when this
                      occurs, we are taking all of the necessary precautions we
                      can to protect you and your family. Precautions include:
                    </p>
                    <ul>
                      <li>
                        Maintaining CDC recommended social distancing practices
                      </li>
                      <li>
                        Ensuring all vendor employees go through health
                        screening protocols prior to visiting your home
                      </li>
                      <li>
                        Requiring all vendor representatives to wear masks and
                        gloves during their visit
                      </li>
                    </ul>

                    <p>
                      If you think you, or someone in your household, may be
                      sick or exposed to someone that was infected with
                      COVID-19, please let the vendor representative know. We’ll
                      find alternate arrangements as their safety is important
                      to us as well
                    </p>

                    <p>
                      Should you have any questions about the precautions that
                      are being taken, or are uncomfortable with this process,
                      please reach out to your Loanpal representative.
                    </p>
                  </RawHTMLStyled>
                </div>
              </div>
            </ContentGrid>
            <ScrollToTop modifier={["skewed"]} guides={0} />
          </Block>
        </PointerParent>
      </Main>
    </div>
  );
}
